<template>
    <div>
        <label v-if="label" class="block mb-1 text-sm font-medium text-gray-700" :for="id">{{ label }} <span v-show="optional" class="inline-flex items-center ml-1 px-2 py-0.5 rounded-md text-xs font-normal bg-gray-100 text-gray-600 select-none">Optional</span></label>

        <div class="w-full">
            <div class="relative flex-1">
                <input
                    :type="type"
                    :id="id"
                    :value="modelValue"
                    @input="updateValue"
                    v-bind="$attrs"
                    :name="uuid()"
                    autocomplete="new-password"
                    autocorrect="off"
                    class="w-full px-3 py-3 rounded border focus:outline-none focus:ring-2 focus:border-transparent"
                    :class="[type !== 'date' ? 'pr-10' : '', error ? 'border-red-500 text-red-900 placeholder-red-300 focus:ring-red-500' : 'border-gray-300 text-grey-900 placeholder-grey-300 focus:ring-indigo-600']"
                />

                <div v-show="error" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    <!-- <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" /> -->
                </div>
            </div>
        </div>

        <p v-show="error" class="mt-1 text-sm text-red-600">{{ error }}</p>
    </div>
</template>

<script>
    import { v4 as uuidv4 } from 'uuid';
    // import { ExclamationCircleIcon } from '@heroicons/vue/solid';

    export default {
        components: {
            // ExclamationCircleIcon,
        },
        inheritAttrs: false,
        props: {
            id: { type: String, required: true },
            label: { type: String, required: false },
            type: { type: String, required: false, default: 'text' },
            modelValue: { required: true },
            optional: { type: Boolean, default: false },
            error: { type: String, required: false },
        },
        methods: {
            updateValue(event) {
                this.$emit('update:modelValue', event.target.value);
            },
            uuid() {
                return uuidv4();
            },
        },
    };
</script>

<style scoped>
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }
</style>
