export function electricity_endex_data(state) {
    return state.electricity_endex_data;
}
export function week_endex_data_y1(state) {
    const data = state.electricity_endex_data.last_week_elec_data;
    if (data) {
        const result = data.filter((item) => item.type_value === '+1');
        result.shift();
        return result;
    }
}
export function week_endex_data_y2(state) {
    const data = state.electricity_endex_data.last_week_elec_data;
    if (data) {
        const result = data.filter((item) => item.type_value === '+2');
        result.shift();
        return result;
    }
}
export function week_endex_data_y3(state) {
    const data = state.electricity_endex_data.last_week_elec_data;
    if (data) {
        const result = data.filter((item) => item.type_value === '+3');
        result.shift();
        return result;
    }
}
export function electricity_monthly_endex_data(state) {
    return state.electricity_monthly_endex_data;
}

// BELPEX

export function hours_in_day() {
    var hours = [],
        i;
    for (i = 0; i < 24; i++) {
        hours.push(i);
    }
    return hours;
}
export function elec_epex_spot_data(state) {
    return state.elec_epex_spot_data;
}
export function last_week_belpex_data(state) {
    if (state.elec_epex_spot_data.last_week_belpex_data) {
        const last_week_data = state.elec_epex_spot_data.last_week_belpex_data;
        return last_week_data;
    } else {
        return [];
    }
}
export function belpex_monday(state) {
    if (state.elec_epex_spot_data.last_week_belpex_data) {
        const monday = state.elec_epex_spot_data.last_week_belpex_data.monday;
        return monday;
    } else {
        return [];
    }
}
export function belpex_tuesday(state) {
    if (state.elec_epex_spot_data.last_week_belpex_data) {
        const tuesday = state.elec_epex_spot_data.last_week_belpex_data.tuesday;
        return tuesday;
    } else {
        return [];
    }
}
export function belpex_wednesday(state) {
    if (state.elec_epex_spot_data.last_week_belpex_data) {
        const wednesday = state.elec_epex_spot_data.last_week_belpex_data.wednesday;
        return wednesday;
    }
}
export function belpex_thursday(state) {
    if (state.elec_epex_spot_data.last_week_belpex_data) {
        const thursday = state.elec_epex_spot_data.last_week_belpex_data.thursday;
        return thursday;
    } else {
        return [];
    }
}
export function belpex_friday(state) {
    if (state.elec_epex_spot_data.last_week_belpex_data) {
        const friday = state.elec_epex_spot_data.last_week_belpex_data.friday;
        return friday;
    } else {
        return [];
    }
}
export function belpex_saturday(state) {
    if (state.elec_epex_spot_data.last_week_belpex_data) {
        const saturday = state.elec_epex_spot_data.last_week_belpex_data.saturday;
        return saturday;
    } else {
        return [];
    }
}
export function belpex_sunday(state) {
    if (state.elec_epex_spot_data.last_week_belpex_data) {
        const sunday = state.elec_epex_spot_data.last_week_belpex_data.sunday;
        return sunday;
    } else {
        return [];
    }
}
export function elec_monthly_epex_spot_chart(state) {
    return state.elec_monthly_epex_spot_data;
}
export function elec_monthly_epex_spot_data(state) {
    return state.elec_monthly_epex_spot_data.epex_spot_monthly_data;
}
export function elec_monthly_epex_spot_data_y0(state) {
    if (state.elec_monthly_epex_spot_data.epex_spot_monthly_data) {
        return state.elec_monthly_epex_spot_data.epex_spot_monthly_data.filter((item) => item.month.substr(0, 4) === new Date().getFullYear().toString());
    } else {
        return [];
    }
}
export function elec_monthly_epex_spot_data_y1(state) {
    if (state.elec_monthly_epex_spot_data.epex_spot_monthly_data) {
        return state.elec_monthly_epex_spot_data.epex_spot_monthly_data.filter((item) => item.month.substr(0, 4) === (new Date().getFullYear() - 1).toString());
    } else {
        return [];
    }
}
export function elec_monthly_epex_spot_data_y2(state) {
    if (state.elec_monthly_epex_spot_data.epex_spot_monthly_data) {
        return state.elec_monthly_epex_spot_data.epex_spot_monthly_data.filter((item) => item.month.substr(0, 4) === (new Date().getFullYear() - 2).toString());
    } else {
        return [];
    }
}
export function elec_monthly_epex_spot_data_y3(state) {
    if (state.elec_monthly_epex_spot_data.epex_spot_monthly_data) {
        return state.elec_monthly_epex_spot_data.epex_spot_monthly_data.filter((item) => item.month.substr(0, 4) === (new Date().getFullYear() - 3).toString());
    } else {
        return [];
    }
}

// GAS
export function gas_ttf_data(state) {
    return state.gas_ttf_data;
}
export function week_ttf_data_y1(state) {
    const data = state.gas_ttf_data.last_week_gas_data;
    if (data) {
        const result = data.filter((item) => item.type_value === '+1');
        result.shift();
        return result;
    }
}
export function week_ttf_data_y2(state) {
    const data = state.gas_ttf_data.last_week_gas_data;
    if (data) {
        const result = data.filter((item) => item.type_value === '+2');
        result.shift();
        return result;
    }
}
export function week_ttf_data_y3(state) {
    const data = state.gas_ttf_data.last_week_gas_data;
    if (data) {
        const result = data.filter((item) => item.type_value === '+3');
        result.shift();
        return result;
    }
}
export function last_week_ttf_spot_data(state) {
    if (state.gas_ttf_spot_data.last_week_data) {
        const last_week_data = state.gas_ttf_spot_data.last_week_data;
        return last_week_data;
    }
}
export function gas_monthly_endex_data(state) {
    return state.gas_monthly_endex_data;
}
export function gas_ttf_spot_data(state) {
    return state.gas_ttf_spot_data;
}
export function gas_ztp_spot_data(state) {
    return state.gas_ztp_spot_data;
}
export function gas_ttf_dam_data(state) {
    return state.gas_ttf_dam_data;
}
export function emission_data(state) {
    return state.emission_data;
}
export function week_emission_data(state) {
    const data = state.emission_data.last_week_emissions;
    if (data) {
        data.shift();
        return data;
    }
}
