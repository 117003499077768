export function SET_ELEC_ENDEX_DATA(state, electricity_endex_data) {
    state.electricity_endex_data = electricity_endex_data;
}
export function SET_ELEC_MONTHLY_ENDEX_DATA(state, electricity_monthly_endex_data) {
    state.electricity_monthly_endex_data = electricity_monthly_endex_data;
}
export function SET_ELEC_BELPEX_DATA(state, elec_epex_spot_data) {
    state.elec_epex_spot_data = elec_epex_spot_data;
}
export function SET_ELEC_EPEX_MONTHLY_DATA(state, elec_monthly_epex_spot_data) {
    state.elec_monthly_epex_spot_data = elec_monthly_epex_spot_data;
}
export function SET_GAS_TTF_DATA(state, gas_ttf_data) {
    state.gas_ttf_data = gas_ttf_data;
}
export function SET_GAS_TTF_SPOT_DATA(state, gas_ttf_spot_data) {
    state.gas_ttf_spot_data = gas_ttf_spot_data;
}
export function SET_GAS_ZTP_SPOT_DATA(state, gas_ztp_spot_data) {
    state.gas_ztp_spot_data = gas_ztp_spot_data;
}
export function SET_GAS_TTF_DAM_DATA(state, gas_ttf_dam_data) {
    state.gas_ttf_dam_data = gas_ttf_dam_data;
}
export function SET_EMISSION_DATA(state, emission_data) {
    state.emission_data = emission_data;
}
