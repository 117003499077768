export default {
    amount_of_days: '1 dag | {count} dagen',
    amount_of_weeks: '1 week | {count} weken',
    amount_of_months: '1 maand | {count} maanden',
    amount_of_years: '1 jaar | {count} jaren',
    average_last_month: 'Gemiddelde vorige maand | Gemiddelde vorige {count} maanden',
    average_last_year: 'Gemiddelde vorig jaar | Gemiddelde vorige {count} jaren',
    belgian_energy_price: 'Belgian Energy Price | Belgian Energy Prices',
    belpex: 'EPEX Spot',
    de: 'DE',
    date: 'Datum | Data',
    difference: 'Verschil | Verschillen',
    duration_choice: 'Kies uw periode | Kies uw periodes',
    end_date: 'Eind datum | Eind data',
    energy_data: 'Energie data | Energie data',
    endex_ttf: 'Endex TTF',
    endex: 'Endex BE',
    endex_monthly: 'Endex BE (maandelijks)',
    evolution: 'Evolutie | Evoluties',
    electricity: 'Elektriciteit | Elektriciteit',
    emission: 'Emissie | Emissies',
    fr: 'FR',
    friday: 'Vrijdag | Vrijdagen',
    gas: 'Gas',
    january: 'Januari',
    july: 'Juli',
    june: 'Juni',
    language: 'Taal | Talen',
    loading: 'Loading',
    monday: 'Maandag | Maandagen',
    month: 'Maand | Maanden',
    monthly_epex_spot: 'EPEX Spot (maandelijks)',
    nl: 'NL',
    past_week: 'Data van de voorbije week',
    period: 'Periode | Periodes',
    saturday: 'Zaterdag | Zaterdagen',
    select_date: 'Kies datum | Kies data',
    start_date: 'Start datum | Start data',
    sunday: 'Zondag | Zondagen',
    thursday: 'Donderdag | Donderdagen',
    time: 'Tijd | Tijden',
    ttf: 'TTF',
    ttf_average_last_month: 'Gemiddelde TTF van de vorige maand',
    ztp_average_last_month: 'Gemiddelde ZTP van de vorige maand',
    ttf_ztp_spot_market: 'TTF/ZTP Spot markt | TTF/ZTP Spot markten',
    ttf_dam: 'TTF Day Ahead',
    tuesday: 'Dinsdag | Dinsdagen',
    value: 'Waarde | Waardes',
    wednesday: 'Woensdag | Woensdagen',
    year: 'Jaar | Jaren',
    year_1: 'Jaar + 1',
    year_2: 'Jaar + 2',
    year_3: 'Jaar + 3',
    ztp: 'ZTP',
};
