import EnergyDataService from '../../services/EnergyDataService';

export async function fetchElecEndexData(context) {
    try {
        const response = await EnergyDataService.getElectricityEndexData();
        const electricity_endex_data = response.data.electricity_endex_data;
        return context.commit('SET_ELEC_ENDEX_DATA', electricity_endex_data);
    } catch (error) {
        console.log(error);
        return error;
    }
}
export async function fetchElecMonthlyEndexData(context) {
    try {
        const response = await EnergyDataService.getElectricityMonthlyEndexData();
        const electricity_monthly_endex_data = response.data.electricity_monthly_endex_data;
        return context.commit('SET_ELEC_MONTHLY_ENDEX_DATA', electricity_monthly_endex_data);
    } catch (error) {
        console.log(error);
        return error;
    }
}
export async function fetchElecEpexSpotData(context) {
    try {
        const response = await EnergyDataService.getElectricityEpexSpotData();
        const elec_epex_spot_data = response.data.elec_epex_spot_data;
        return context.commit('SET_ELEC_BELPEX_DATA', elec_epex_spot_data);
    } catch (error) {
        console.log(error);
        return error;
    }
}
export async function fetchElecEpexSpotMonthlyData(context) {
    try {
        const response = await EnergyDataService.getElecEpexSpotMonthlyData();
        const elec_monthly_epex_spot_data = response.data.elec_monthly_epex_spot_data;
        return context.commit('SET_ELEC_EPEX_MONTHLY_DATA', elec_monthly_epex_spot_data);
    } catch (error) {
        console.log(error);
        return error;
    }
}
export async function fetchGasTtfData(context) {
    try {
        const response = await EnergyDataService.getGasTtfData();
        const gas_ttf_data = response.data.gas_ttf_data;
        return context.commit('SET_GAS_TTF_DATA', gas_ttf_data);
    } catch (error) {
        console.log(error);
        return error;
    }
}
export async function fetchGasTtfSpotData(context) {
    try {
        const response = await EnergyDataService.getGasTtfSpotData();
        const gas_ttf_spot_data = response.data.gas_ttf_spot_data;
        return context.commit('SET_GAS_TTF_SPOT_DATA', gas_ttf_spot_data);
    } catch (error) {
        console.log(error);
        return error;
    }
}
export async function fetchGasZtpSpotData(context) {
    try {
        const response = await EnergyDataService.getGasZtpSpotData();
        const gas_ztp_spot_data = response.data.gas_ztp_spot_data;
        return context.commit('SET_GAS_ZTP_SPOT_DATA', gas_ztp_spot_data);
    } catch (error) {
        console.log(error);
        return error;
    }
}
export async function fetchGasTtfDamData(context) {
    try {
        const response = await EnergyDataService.getGasTtfDamData();
        const gas_ttf_dam_data = response.data.gas_ttf_dam_data;
        return context.commit('SET_GAS_TTF_DAM_DATA', gas_ttf_dam_data);
    } catch (error) {
        console.log(error);
        return error;
    }
}
export async function fetchEmissionData(context) {
    try {
        const response = await EnergyDataService.getEmissionData();
        const emission_data = response.data.emission_data;
        return context.commit('SET_EMISSION_DATA', emission_data);
    } catch (error) {
        console.log(error);
        return error;
    }
}
