export default {
    amount_of_days: '1 day | {count} days',
    amount_of_weeks: '1 week | {count} weeks',
    amount_of_months: '1 month | {count} months',
    amount_of_years: '1 year | {count} years',
    average_last_month: 'Average last month | Average last {count} months',
    average_last_year: 'Average last year | Average last {count} years',
    belgian_energy_price: 'Belgian Energy Price | Belgian Energy Prices',
    belpex: 'EPEX Spot',
    de: 'DE',
    date: 'Date | Dates',
    difference: 'Difference | Differences',
    duration_choice: 'Duration choice | Duration choices',
    end_date: 'End date | End dates',
    energy_data: 'Energy data | Energy data',
    endex_ttf: 'Endex TTF',
    endex: 'Endex BE',
    endex_monthly: 'Endex BE (monthly)',
    evolution: 'Evolution | Evolutions',
    electricity: 'Electricity | Electricities',
    emission: 'Emission | Emissions',
    fr: 'FR',
    friday: 'Friday | Fridays',
    gas: 'Gas',
    january: 'January',
    july: 'July',
    june: 'June',
    language: 'Language | Languages',
    loading: 'Loading',
    monday: 'Monday | Mondays',
    month: 'Month | Months',
    monthly_epex_spot: 'EPEX Spot (monthly)',
    nl: 'NL',
    past_week: 'Data from the past week',
    period: 'Period | Periods',
    saturday: 'Saturday | Saturdays',
    select_date: 'Select date | Select dates',
    start_date: 'Start date | Start dates',
    sunday: 'Sunday | Sundays',
    thursday: 'Thursday | Thursdays',
    time: 'Time | Times',
    ttf: 'TTF',
    ttf_average_last_month: 'Average TTF last month',
    ztp_average_last_month: 'Average ZTP last month',
    ttf_ztp_spot_market: 'TTF/ZTP Spot market | TTF/ZTP Spot markets',
    ttf_dam: 'TTF Day Ahead',
    tuesday: 'Tuesday | Tuesdays',
    value: 'Value | Values',
    wednesday: 'Wednesday | Wednesdays',
    year: 'Year | Years',
    year_1: 'Year + 1',
    year_2: 'Year + 2',
    year_3: 'Year + 3',
    ztp: 'ZTP',
};
