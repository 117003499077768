import { createStore } from 'vuex';

import energy_data from './energy_data';
import loader from './loader';

const store = createStore({
    modules: {
        energy_data,
        loader,
    },
});

export default store;
