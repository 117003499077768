export default {
    amount_of_days: '1 jour | {count} jours',
    amount_of_weeks: '1 semaine | {count} semaines',
    amount_of_months: '1 mois | {count} mois',
    amount_of_years: '1 an | {count} ans',
    average_last_month: 'Moyenne du dernier mois | Moyenne des {count} mois précédents',
    average_last_year: 'Moyenne de la dernière année | Moyenne des {count} années précédentes',
    belgian_energy_price: 'Belgian Energy Price | Belgian Energy Prices',
    belpex: 'EPEX Spot',
    de: 'DE',
    date: 'Date | Dates',
    difference: 'Différence | Différences',
    duration_choice: 'Choisissez votre période | Choisissez votre périodes',
    end_date: 'Date de fin | Dates de fin',
    energy_data: "Données sur l'énergie | Données sur l'énergie",
    endex_ttf: 'Endex TTF',
    endex: 'Endex BE',
    endex_monthly: 'Endex BE (mensuel)',
    evolution: 'Evolution | Evolutions',
    electricity: 'Électricité | Électricité',
    emission: 'Emissions | Emissions',
    fr: 'FR',
    friday: 'Vendredi | Vendredi',
    gas: 'Gaz',
    january: 'Janvier',
    july: 'Juillet',
    june: 'June',
    language: 'Langue | Langues',
    loading: 'Loading',
    monday: 'Lundi | Lundis',
    month: 'Mois | Mois',
    monthly_epex_spot: 'EPEX Spot (mensuel)',
    nl: 'NL',
    past_week: 'Dates de la semaine dernière',
    period: 'Période | Périodes',
    saturday: 'samedi | samedis',
    select_date: 'Choisir la date | Choisir les dates',
    start_date: 'Date de début | Dates de début',
    sunday: 'Dimanche | Dimanche',
    thursday: 'Jeudi | Jeudi',
    time: 'Time | Times',
    ttf: 'TTF',
    ttf_average_last_month: 'TTF moyen du dernier mois',
    ztp_average_last_month: 'ZTP moyenne du dernier mois',
    ttf_ztp_spot_market: 'Marché TTF/ZTP Spot | Marchés TTF/ZTP Spot',
    ttf_dam: 'TTF Day Ahead',
    tuesday: 'Mardi | Mardi',
    value: 'Valeur | Valeurs',
    wednesday: 'Mercredi | Mercredi',
    year: 'Année | Années',
    year_1: 'Année + 1',
    year_2: 'Année + 2',
    year_3: 'Année + 3',
    ztp: 'ZTP',
};
