<template>
    <div class="flex items-center" :class="[full ? 'w-full min-h-full justify-center' : '']">
        <span class="rounded-full block border-gray-200 animate-spin border-top-color" :class="[size === 'large' ? 'border-2 w-8 h-8' : size === 'medium' ? 'border-2 w-6 h-6' : 'border w-4 h-4']" />

        <div class="text-gray-700" :class="[size === 'large' ? 'ml-4 text-xl' : size === 'medium' ? 'ml-3 text-base' : 'ml-2 text-sm']">
            <slot> {{ $tc('loading', 1) }}... </slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            size: { type: String, default: 'small' },
            full: { type: Boolean, default: false },
        },
    };
</script>

<style scoped>
    .border-top-color {
        border-top-color: #374151;
    }
</style>
