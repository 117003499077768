export function SHOW_LOADER(state) {
    if (state.requestsPending === 0) {
        state.isLoading = true;
    }

    state.requestsPending++;
}

export function HIDE_LOADER(state) {
    if (state.requestsPending >= 1) {
        state.requestsPending--;
    }

    if (state.requestsPending <= 0) {
        state.isLoading = false;
    }
}
