import { createI18n } from 'vue-i18n';
import english from '@/i18n/general/en';
import dutch from '@/i18n/general/nl';
import french from '@/i18n/general/fr';
import german from '@/i18n/general/de';

const messages = {
    en: {
        ...english,
    },
    nl: {
        ...dutch,
    },
    fr: {
        ...french,
    },
    de: {
        ...german,
    },
};

function getBrowserLocale(options = {}) {
    const defaultOptions = { countryCodeOnly: false };

    const opt = { ...defaultOptions, ...options };

    const navigatorLocale = navigator.languages !== undefined ? navigator.languages[0] : navigator.language;

    if (!navigatorLocale) {
        return undefined;
    }

    const trimmedLocale = opt.countryCodeOnly ? navigatorLocale.trim().split(/-|_/)[0] : navigatorLocale.trim();

    console.log('trimmed', trimmedLocale);
    return trimmedLocale;
}

export const i18n = createI18n({
    locale: getBrowserLocale({ countryCodeOnly: true }), // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
});
