import axios from 'axios';
import store from '@/store';

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_ENDPOINT,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    showLoader: true,
    timeout: 10000,
});

apiClient.interceptors.request.use(
    (config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem(process.env.VUE_APP_API_TOKEN_NAME)}`;

        if (config.showLoader) {
            store.dispatch('loader/show');
        }

        return config;
    },
    (error) => {
        store.dispatch('loader/hide');
        return Promise.reject(error);
    }
);

apiClient.interceptors.response.use(
    (response) => {
        store.dispatch('loader/hide');
        return Promise.resolve(response);
    },
    (error) => {
        store.dispatch('loader/hide');
        return Promise.reject(error);
    }
);

export default apiClient;
