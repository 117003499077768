import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import store from '@/store';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

// Check Auth
router.beforeEach((to, _, next) => {
    const isLoggedIn = store.getters['auth/isLoggedIn'];
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const requiresGuest = to.matched.some((record) => record.meta.redirectIfLoggedIn);

    if (requiresAuth && !isLoggedIn) {
        next('/login');
    } else if (requiresGuest && isLoggedIn) {
        next('/');
    } else {
        next();
    }
});

export default router;
