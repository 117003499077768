import { createApp } from 'vue';
import vClickOutside from 'click-outside-vue3';
import App from './App.vue';
import router from './router';
import store from './store';
import './styles/tailwind.css';

import PageLoader from '@/components/general/PageLoader.vue';
import Spinner from '@/components/general/Spinner.vue';
import RadioButtonGroup from '@/components/formElements/RadioButtonGroup.vue';
import Input from '@/components/formElements/Input.vue';
import Select from '@/components/formElements/Select.vue';
import Header from '@/components/general/Header.vue';

// Setup translations
import { i18n } from './i18n';
import moment from 'moment';

// createApp(App).use(store).use(router).use(i18n).mount('#app');

const app = createApp(App);

// Components
app.component('Spinner', Spinner);
app.component('PageLoader', PageLoader);
app.component('RadioButtonGroup', RadioButtonGroup);
app.component('Input', Input);
app.component('Select', Select);
app.component('Header', Header);

app.use(store);
app.use(router);
app.use(i18n);
app.use(vClickOutside);

app.config.globalProperties.$filters = {
    getHumanDate(date) {
        if (date != null) {
            return moment(date).format('DD/MM/YYYY');
        }
        return '-';
    },
    intToTime(time) {
        const base_time = moment().startOf('day');
        return moment(base_time).add(time, 'hours').format('HH:mm');
    },
    getHumanPrice(value, decimals) {
        return parseFloat(value).toFixed(decimals) + ' €';
    },
    getDecimalValue(value, decimals) {
        return parseFloat(value).toFixed(decimals);
    },
    getMonthYear(date) {
        return moment(date, 'YYYY-MM-DD').format('YYYY-MM');
    },
    makeDecimal(value) {
        if (typeof value !== 'undefined') {
            return value.replace(',', '.');
        } else {
            value;
        }
    },
};

app.mount('#app');
