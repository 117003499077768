export default function () {
    return {
        electricity_endex_data: [],
        electricity_monthly_endex_data: [],
        elec_epex_spot_data: [],
        elec_monthly_epex_spot_data: [],
        gas_ttf_data: [],
        gas_ttf_spot_data: [],
        gas_ztp_spot_data: [],
        gas_ttf_dam_data: [],
        emission_data: [],
    };
}
