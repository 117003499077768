<template>
    <Spinner v-if="isLoading" full size="medium" />
    <slot v-else></slot>
</template>

<script>
    export default {
        computed: {
            isLoading() {
                return this.$store.getters['loader/isLoading'];
            },
        },
    };
</script>
